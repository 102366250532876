<template>
  <div>
    
    <h4><strong>Cineco Show Details</strong></h4>
    <hr />

    <b-card>
      <b-card-header>
        <i class="icon-menu mr-1"></i>List of Show Details
        <div class="card-header-actions"></div>
      </b-card-header>
      <b-card-body>
       <div class="show-details-scroll">
        <table class="table table-striped">
          <tbody>
            <tr>
              <td><b>Movie Name</b> </td>
              <td><b>Seef</b></td>
              <td><b>Al Hamra</b></td>
              <td><b>Juffair</b></td>
              <td><b>Wadi</b></td>
            </tr>

            <tr v-for="(item, index) in detailsList" :key="index">

              <td>{{item.movie}} </td>
              <template v-for="(data, data_index) in item.cinemas">

                <td v-if="data.CinemaName.includes('Seef')" :key="index+data_index">
                {{ data.Sessions }}
               </td>
               <td v-if="data.CinemaName.includes('Hamra')" :key="index+data_index">
                {{ data.Sessions }}
               </td>
               <td v-if="data.CinemaName.includes('Juffair')" :key="index+data_index">
                {{ data.Sessions }}
               </td>
               <td v-if="data.CinemaName.includes('Wadi')" :key="index+data_index">
                {{ data.Sessions }}
               </td>
              </template>
             
            </tr>
          </tbody>
        </table>
      </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import MasterService from "@/services/MasterService";

export default {
  data() {
    return {
      pagination: {
        total: 1,
        current: 1,
        pageSize: 10,
      },
      
      detailsList: [],
    };
  },

  mounted() {

    MasterService.getCinecoSession()
      .then((response) => {
        this.detailsList = response.data.SessionData;
        console.log(this.detailsList,"detailssssssss")
      })
      .catch((error) => {
        console.log("Catch on Getting Event Error: ", error);
      });

  },

  computed: {
    rows() {
      return this.filteredData.length;
    },
  },

  methods: {
    
    // filter() {
    //   let payload = {
    //     event_name: this.event_name,
    //     status: this.event_status,
    //     event_start_date: this.event_start_date,
    //     event_end_date: this.event_end_date,
    //   }

    //   MasterService.filterEvents(payload)
    //     .then((response) => {
    //       this.eventList = response.data.data           
    //     })
    //     .catch((error) => {
    //       console.log("Catch on Getting Reports Filter ", error);
    //     });
    // },

    //   resetData() {
    //     this.filteredData = this.allData;
    //     this.event_start_date = "";
    //     this.event_end_date = "";
    //     this.event_name = "";
    //     this.event_status = "";
    //   },

    //   editBanner: function (event_id) {
    //     if(event_id){
    //         this.$router.push({
    //             path:'/eventinfo/addeventinfo',
    //             query:{event_id}
    //         })
    //     }
    //   }
  },
};
</script>

<style>
.show-details-scroll {
  height: 400px;
  overflow-y: auto;
}
/* .banner-list li  */
</style>
